import { h } from 'preact'
import { useState } from 'preact/hooks'
import { asText, RichText } from '../lib/prismic-render.js'
import Swiper from 'react-id-swiper'
import { Img } from './img.js'

export function Gallery(props) {
	let Component

	switch (props.primary.style) {
		case 'list':
			Component = List
			break
		case 'carousel':
		case 'carousel_small':
		default:
			Component = Carousel
	}

	return <Component {...props} />
}

function List(props) {
	const [index, setIndex] = useState(0)

	return (
		<div class="psr z4 container">
			<div class="x xw gutter">
				<div class="psr ar75 c12 md-co2 md-c6">
					<Img class="psa t0 l0 w100 h100" style="object-fit: cover" {...props.items[index].image} />
				</div>
				<div class="c12 md-c3 x xac">
					{/* FIXME use semantic list? */}
					<ul>
						{props.items.map((item, index) => (
							<button class="tal" onClick={() => setIndex(index)}>
								<li class="px1 pb0-5" style="list-style: inside;">{item.heading && asText(item.heading)}</li>
							</button>
						))}
					</ul>
				</div>
			</div>
		</div>
	)
}

function Carousel(props) {
	if (
		!props ||
		!props.primary ||
		!props.items ||
		!props.items.length
	) {
		return null
	}
	const settings = props.primary.style === 'carousel_small'
		? {
			spaceBetween: 20,
			slidesPerView: 1,
			breakpoints: {
				600: {
					slidesPerView: 2
				},
				1000: {
					slidesPerView: 3
				},
				1400: {
					slidesPerView: 4
				}
			}
		}
		: {
			spaceBetween: 20
		}

	return (
		<div style="overflow: hidden">
			<div class="container">
				<Swiper {...settings}>
					{props.items.map(item => (
						<div>
							<Img {...item.image} />
							{item.heading && !!item.heading.length && (
								<h5 class="h5 my0-5">{asText(item.heading)}</h5>
							)}
							{item.description && !!item.description.length && (
								<div class="fp2 mt0-5">
									<RichText richText={item.description} />
								</div>
							)}
						</div>
					))}
				</Swiper>
			</div>
		</div>
	)
}
