// https://prismic.io/docs/rest-api/basics/introduction-to-the-content-query-apis
import * as cookies from './cookies.js'

export const PREVIEW_COOKIE = 'io.prismic.preview'

function defaultGet(url) {
	return fetch(url).then(res => res.json())
}

export async function getApi(endpoint, { req, get = defaultGet } = {}) {
	const api = await get(endpoint)
	const masterRef = api.refs.filter(ref => ref.isMasterRef)[0]
	const previewRef = cookies.get(PREVIEW_COOKIE, req && req.headers.cookie)

	function query(predicates, opts = {}) {
		opts.ref = opts.ref || previewRef || masterRef.ref

		predicates = Array.isArray(predicates) ? predicates : [predicates]
		predicates = predicates.map(p => ['q', p])

		const params = [...predicates, ...Object.entries(opts)]
		const query = params.map(([k, v]) => `${k}=${encodeURIComponent(v)}`).join('&')
		const url = `${endpoint}/documents/search?${query}`

		return get(url)
	}

	function queryFirst(predicates, opts) {
		return query(predicates, opts).then(res => res && res.results && res.results[0])
	}

	function getSingle(type, opts) {
		return queryFirst(`[[at(document.type, "${type}")]]`, opts)
	}

	function getByID(id, opts) {
		return queryFirst(`[[at(document.id, "${id}")]]`, opts)
	}

	function getByUID(type, uid, opts) {
		return queryFirst(`[[at(my.${type}.uid, "${uid}")]]`, opts)
	}

	function previewSession(token, linkResolver, defaultUrl) {
		return new Promise((resolve, reject) => {
			get(token).then(result => {
				if (!result.mainDocument) {
					resolve(defaultUrl)
				} else {
					getByID(result.mainDocument, { ref: token }).then(document => {
						if (!document) {
							resolve(defaultUrl)
						} else {
							resolve(linkResolver(document))
						}
					})
				}
			}).catch(reject)
		})
	}

	return {
		query,
		queryFirst,
		getSingle,
		getByID,
		getByUID,
		previewSession
	}
}
