import { html } from 'htm/preact'
import { asText, RichText } from '../lib/prismic-render.js'
import { Img } from './img.js'
import { Logotype } from './logo.js'

export function Header(props) {
	if (props.primary && props.primary.style === 'home') {
		return html`<${HeaderHome} ...${props} />`
	}
	return html`<${HeaderDefault} ...${props} />`
}

function HeaderDefault(props) {
	return html`
		<header class="container" style="background-color: var(--background-color)">
			<div class="x xw gutter">
				<div class="psst z5 c12 md-c7 md-co3 copy mbmd fc-diffblack" style=" top: 8rem;">
					<!-- FIXME clean this up by adding a textEmpty() helper -->
					${props.primary.heading && props.primary.heading[0] && props.primary.heading[0].text && html`
						<h2 class="h1">${asText(props.primary.heading)}</h2>
					`}
					${props.primary.text && props.primary.text[0] && props.primary.text[0].text && html`
						<${RichText} richText=${props.primary.text} />
					`}
				</div>
				<div class="c12 md-c9 md-co2">
					${(props.primary.embed && props.primary.embed.html)
						? html`
							<div
								class="embed_wrapper z4"
								style="padding-bottom: ${props.primary.embed.height / props.primary.embed.width * 100}%"
								dangerouslySetInnerHTML=${{ __html: props.primary.embed.html }} />
						`
						: html`
							<${Img} class="psr z4" ...${props.primary.image} />
						`}
				</div>
			</div>
		</header>
	`
}

function HeaderHome(props) {
	return html`
		<header class="psr container" style="background-color: var(--background-color); margin-top: 0">
			<div class="psa z5 t0 l0 w100 h100 fc-diffblack mbmd">
				<div class="psst x xw gutter" style="top: 3rem">
					<div class="c12 md-c8">
						<h1><${Logotype} /></h1>
					</div>
					<div class="c12 md-co1 md-c4">
						<p class="h3">Institute for Creative Arts and Technology</p>
					</div>
				</div>
			</div>
			<div class="x xw gutter">
				<div class="c12 md-c7 md-co5 mt5">
					${(props.primary.embed && props.primary.embed.html)
						? html`
							<div
								class="embed_wrapper z4"
								style="padding-bottom: ${props.primary.embed.height / props.primary.embed.width * 100}%"
								dangerouslySetInnerHTML=${{ __html: props.primary.embed.html }} />
						`
						: html`
							<${Img} class="psr z4" ...${props.primary.image} />
						`}
				</div>
			</div>
		</header>
	`
}
