import { h } from 'preact'
import { linkResolver } from '../lib/link-resolver.js'
import { RichText, asText } from '../lib/prismic-render.js'
import { Img } from './img.js'

export function Banner(props) {
	if (!props || !props.primary) {
		return null
	}

	return (
		<div class="container">
			<aside class={`banner banner-${props.primary.style || 'standard'}`}>
				<div class="banner_content">
					<div class="banner_content_inner">
						<header>
							{/* FIXME better existence check? */}
							{props.primary.heading && (
								<h2>{asText(props.primary.heading)}</h2>
							)}

							{/* FIXME better existence check? */}
							{props.primary.text && (
								<RichText richText={props.primary.text} />
							)}
						</header>

						{props.primary.link && props.primary.link_text && (
							<footer>
								<a class={`button button-large ${
									['testimonial', 'support'].includes(props.primary.style)
										? ''
										: 'button-invert button-flat'
								}`} href={linkResolver(props.primary.link)}>
									{props.primary.link_text}
								</a>
							</footer>
						)}
					</div>
				</div>

				{props.primary.image && props.primary.image.url && (
					<figure>
						<Img {...props.primary.image} />
					</figure>
				)}
			</aside>
		</div>
	)
}
