import { html } from 'htm/preact'
import { asText, RichText } from '../lib/prismic-render.js'
import { linkResolver } from '../lib/link-resolver.js'
import { useContent } from '../lib/content.js'
import { unique, delve } from '../lib/util.js'
import { Header } from './header.js'
import { Heading } from './heading.js'
import { Text } from './text.js'
import { Grid } from './grid.js'
import { Image } from './image.js'
import { Embed } from './embed.js'
import { Quote } from './quote.js'
import { Banner } from './banner.js'
import { Gallery } from './gallery.js'
import { Expander } from './expander.js'
import { Marquee } from './marquee.js'
import { Team } from './team.js'
import { Tutors } from './tutors.js'
import { Courses } from './courses.js'
import { Illustration } from './illustration.js'
import { LinkList } from './link-list.js'
import { Search } from './search.js'
import { Img } from './img.js'
import { SocialLinks, OtherLinks } from './social-links.js'

export function Page(props) {
	const page = useContent(props.selector)

	return html`
		<${PageHeader} page=${page} />
		<${PageContent} page=${page} />
	`
}

export function PageContent({ page }) {
	if (!page || !page.data) {
		return null
	}

	return html`
		${page.data.body.map(slice => {
			switch (slice.slice_type) {
				case 'header':
					return html`<${Header}, ...${slice} />`
				case 'heading':
					return html`<${Heading}, ...${slice} />`
				case 'text':
					return html`<${Text}, ...${slice} />`
				case 'quote':
					return html`<${Quote}, ...${slice} />`
				case 'image':
					return html`<${Image}, ...${slice} />`
				case 'gallery':
					return html`<${Gallery}, ...${slice} />`
				case 'embed':
					return html`<${Embed}, ...${slice} />`
				case 'grid':
					return html`<${Grid}, ...${slice} />`
				case 'banner':
					return html`<${Banner}, ...${slice} />`
				case 'expander':
					return html`<${Expander}, ...${slice} />`
				case 'marquee':
					return html`<${Marquee}, ...${slice} />`
				case 'tutors':
					return html`<${Tutors}, tutors=${findTutors(page)} ...${slice} }) />`
				case 'team':
					// TODO remove team later
					return html`<${Team}, ...${slice} />`
				case 'link_list':
					return html`<${LinkList} ...${slice} />`
				case 'search':
					return html`<${Search} ...${slice} />`
				case 'courses':
					return html`<${Courses}, courses=${page.data.courses} ...${slice} }) />`
				case 'illustration':
					return html`<${Illustration}, ...${slice} />`
				default:
					return null
				}
		})}
	`
}

function PageHeader({ page }) {
	if (!page) return null

	switch (page.type) {
		case 'person':
			return html`<${PersonHeader} page=${page} />`
		case 'article':
			return html`<${ArticleHeader} page=${page} />`
		default:
			return null
	}
}

function ArticleHeader({ page }) {
	if (!page || !page.data) {
		return null
	}

	// TODO rename crops to lowercase
	// TODO picture element

	return html`
		<header class="post_hero container">
			${page.data.cover_image && page.data.cover_image.Landscape && html`
				<${Img} ...${page.data.cover_image.Landscape} />
			`}
			${page.data.title && html`
				<h1 class="h2">${asText(page.data.title)}</h1>
			`}
			<p class="fp2">Posted by <a href="">David</a> on 21.09.2019 in <a href="">News</a></p>
		</header>
	`
}

function PersonHeader({ page }) {
	if (!page) return null

	return html`
		<div class="container mt5">
			<div class="x xw gutter">
				<div class="c12 md-co2 md-c4">
					<div class="x xw gutter">
						<div class="c12">
							${page.data.image && html`
								<${Img} ...${page.data.image} />
							`}
						</div>
						<div class="c12 md-co3 md-c9 my0-5">
							${page.data.links && html`
								<${OtherLinks} links=${page.data.links} />
								<${SocialLinks} links=${page.data.links} />
							`}
						</div>
					</div>
				</div>
				<div class="c12 md-c5 copy">
					${page.data.name && html`
						<h1 class="h2">${asText(page.data.name)}</h1>
					`}
					${page.data.byline && html`
						<h5>${page.data.byline}</h5>
					`}
					${page.data.bio && html`
						<${RichText} richText=${page.data.bio} />
					`}
				</div>
			</div>
			${page.data.courses && !!page.data.courses.length && html`
				<div class="x xw gutter my5">
					<div class="c12 md-co3 md-c8">
						<div class="x xw gutter">
							<div class="c12 mb1">
								<h2>Courses</h2>
							</div>
							${page.data.courses.map(course => html`
								<div class="c6">
									<a class="dib w100 tac h4 py2 px1 b2 br1 rise-h" href=${linkResolver(course)}>
										${asText(course.data.title)}
									</a>
								</div>
							`)}
						</div>
					</div>
				</div>
			`}
		</div>
	`
}

function findTutors(page) {
	return delve(page, 'data.courses', [])
		.map(item => delve(item, 'course.data.tutors', []))
		.flat() // TODO polyfill
		.map(item => delve(item, 'tutor', null))
		.filter(Boolean)
		.filter(unique(item => item.id))
}
