import { html } from 'htm/preact'
import { useState, useEffect } from 'preact/hooks'

const KEY = 'catalyst_cookies_accepted'

export function CookieBar() {
	const [visible, setVisible] = useState(false)

	useEffect(() => {
		if (localStorage[KEY] !== 'true') {
			setVisible(true)
		}
	}, [])

	useEffect(() => {
		localStorage[KEY] = visible ? 'false' : 'true'
	}, [visible])

	return html`
		<div class="z9 psf b0 w100 fc-white bgc-black" style="transition: transform 200ms ease-in; ${visible ? '' : 'transform: translateY(100%)'}">
			<div class="container pt0-25 pb0-5 xl-pb0-25">
				<div class="x xac xdc xl-xdr xl-xjb">
					<p class="h6 tac my0-5 xl-my0 xl-tal">
						This website uses cookies for analysis, personalized content and advertisement.
						By using this website, you accept our <a class="tdu-h" href="/TODO">Terms & Conditions</a>.
					</p>
					<div class="x xac">
						<a class="button button-flat mr0-5 wsnw" href="/TODO">Legal Page</a>
						<button class="button button-flat wsnw" onClick=${() => setVisible(false)}>Accept All Cookies</button>
					</div>
				</div>
			</div>
		</div>
	`
}
