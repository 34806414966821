import { html } from 'htm/preact'
import { useEffect } from 'preact/hooks'
import { Route, useLocation, useRoute } from 'wouter-preact'
import { asText } from '../lib/prismic-render.js'
import { PageContent } from './page.js'

export function Courses({ courses }) {
	const [, go] = useLocation()
	const [, { schoolSlug, topicSlug, courseSlug }] = useRoute('/:schoolSlug/:topicSlug/:courseSlug?')

	const firstCourse = courses
		&& courses[0]
		&& courses[0].course

	useEffect(() => {
		if (!courseSlug) {
			// When on the main topic page, redirect to the first course sub-page.
			// This only affects the URL, since we're already showing the first
			// course by default.
			// FIXME use replaceState so that we don't break the back button
			const firstCourseUid = firstCourse && firstCourse.uid
			if (firstCourseUid) {
				go(`/${schoolSlug}/${topicSlug}/${firstCourseUid}`)
			}
		}
	}, [courseSlug])

	return html`
		<section class="courses_section_REPLACE_ME">
			<nav class="container">
				<div class="x xw gutter">
					<div class="c12 md-co3 md-c8">
						<ul class="x xw gutter lsn">
							${courses.map(item => item && item.course && html`
								<li class="c4 mb1">
									<!-- FIXME go over this and see what parts of btn-3d are duplicated / can be extracted -->
									<a
										class="x xac xjc w100 h100 px0-5 py1 b2 br1 rise-h tac ${courseSlug === item.course.uid ? 'fwb' : ''}"
										href=${`/${schoolSlug}/${topicSlug}/${item.course.uid}`}
									>${asText(item.course.data.title)}</a>
								</li>
							`)}
						</ul>
					</div>
				</div>
			</nav>
			${courses.map(item => item && item.course && html`
				<!-- Show the first course automatically on the main topic page -->
				<!-- NOTE: Wouter doesn't support '?' on static segments, so we can't show the default course on first load  -->
				<!-- FIXME: fix this by using a custom matcher based on regexparam -->
				<${Route} path=${`/${schoolSlug}/${topicSlug}/${item.course.uid}`}>
					<${PageContent} page=${item.course} />
				<//>
			`)}
		</section>
	`
}
