import { h } from 'preact'
import { useState, useEffect, useRef } from 'preact/hooks'
import { prismicEndpoint } from '../config'
import { getApi } from '../lib/prismic-api'
import { asText } from '../lib/prismic-render'
import { linkResolver } from '../lib/link-resolver'

export function Search() {
	const api = useRef()

	const [term, setTerm] = useState(null)
	const [{ results, loading, error }, setState] = useState({
		results: null,
		loading: false,
		error: null
	})

	useEffect(async () => {
		api.current = await getApi(prismicEndpoint)
	}, [])

	useEffect(async () => {
		if (term && api.current && !loading) {
			setState({
				results: null,
				loading: true,
				error: null
			})

			try {
				const response = await api.current.query(`[[fulltext(document, "${term}")]]`)

				setState({
					results: response.results,
					loading: false,
					error: null
				})
			} catch (error) {
				setState({
					results: null,
					loading: false,
					error
				})
			}
		}
	}, [term])

	return (
		<div class="container">
			<div class="x xw gutter">
				<div class="c12 md-c8 md-co3">
					<input class="searchbar mb1" type="search" placeholder="Search Resources" onInput={event => setTerm(event.target.value)} />

					<ul style="list-style: none; line-height: 2">
						{results && results.map(item => (
							<li><a href={linkResolver(item)}>{asText(item.data.title || item.data.name || [])}</a></li>
						))}
					</ul>
				</div>
			</div>
		</div>
	)
}
