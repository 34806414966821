import 'preact/debug'
import { h } from 'preact'
import { render, hydrate } from 'preact'
import { HelmetProvider } from 'react-helmet-async'
import { App } from './app.js'

const preloadElement = document.getElementById('preload-data')
const preloadRaw = preloadElement.textContent
const preloadData = JSON.parse(preloadRaw)

const init = process.env.NODE_ENV !== 'production'
	? render // complete client-side render (no SSR)
	: hydrate // only attach event listeners (SSR)

const app = (
	<HelmetProvider>
		<App data={preloadData} />
	</HelmetProvider>
)

init(app, document.getElementById('root'))
