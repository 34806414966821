import { useCallback } from 'preact/hooks'
import { useFormContext } from './useFormContext'

export function useField(field) {
	const { getFieldValue, setFieldValue } = useFormContext()
	const value = getFieldValue(field)
	const setValue = useCallback(value => {
		setFieldValue(field, value)
	}, [field])

	return { value, setValue }
}
