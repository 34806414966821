import { html } from 'htm/preact'
import { RichText } from '../lib/prismic-render.js'

export function Text(props) {
	return html`
		<div class="container">
			<div class="x xw gutter">
				<div class="c12 md-c8 md-co3 copy">
					<${RichText} richText=${props.primary.text} />
				</div>
			</div>
		</div>
	`
}
