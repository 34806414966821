import { html } from 'htm/preact'
import { asText, RichText } from '../lib/prismic-render.js'
import { Img } from './img.js'

// FIXME proper margin-bottom from scale

function Person(props) {
	return html`
		<div class="c12 md-c4 mb3">
			<div class="psr ar100 mb1 filter-grayscale filter-none-h nudge-h">
				${props.image && props.image.url && html`
					<${Img} class="psa t0 l0 w100 h100" style="object-fit: cover" sizeHint=${30} ...${props.image} />
				`}
			</div>
			<p><strong>${asText(props.name)}</strong></p>
			<${RichText} richText=${props.bio} />
		</div>
	`
}

export function Team(props) {
	return html`
		<div class="container">
			<div class="x xw gutter">
				<div class="c12 md-c10 md-co2">
					<div class="x xw gutter">
						${props.items.map(Person)}
					</div>
				</div>
			</div>
		</div>
	`
}
