import { html } from 'htm/preact'
import { RichText } from '../lib/prismic-render.js'

export function Quote(props) {
	return html`
		<blockquote class="container">
			<div class="x xw gutter">
				<div class="c12 md-c9 md-co2 h1">
					<${RichText} richText=${props.primary.quote} />
				</div>
			</div>
		</blockquote>
	`
}
