import { html } from 'htm/preact'
import { Img } from './img.js'

export function Image(props) {
	return html`
		<figure class="container">
			<${Img} class="psr z4" ...${props.primary.image} />
		</figure>
	`
}
