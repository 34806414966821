import { h, Fragment } from 'preact'
import { useState } from 'preact/hooks'
import { Logo, Logotype } from './logo.js'

export function HeaderMobile() {
	const [open, setOpen] = useState(false)

	return (
		<Fragment>
			<TopBarMobile handleClick={() => setOpen(true)} />
			<NavMobile open={open} handleClick={() => setOpen(false)} />
		</Fragment>
	)
}

function TopBarMobile(props) {
	return (
		<header class="psf t0 l0 w100" style="z-index: 99;">
			<div class="container" style="background-color: var(--background-color);">
				<div class="x xjb xac py0-25">
					<a href="/"><Logo /></a>
					<button onClick={props.handleClick}>Menu</button>
				</div>
			</div>
		</header>
	)
}

function NavMobile(props) {
	return (
		<nav class="psf t0 l0 w100" style={`z-index: 100; transition: transform ease 300ms; transform: ${props.open ? 'translateY(0)' : 'translateY(-110%)'}`}>
			<div style="background-color: var(--background-color); box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);">
				<div class="container">
					<div class="x xac xjb">
						<Logotype style={{ width: '200px', height: 'auto' }} />
						<button onClick={props.handleClick}>Close</button>
					</div>
					<div class="p2">
						<div class="x gutter">
							<div class="c6">
								<ul class="fp1" style="list-style: none; line-height: 2;">
									<li><a href="/schools/film">Film</a></li>
									<li><a href="/schools/acting">Acting</a></li>
									<li><a href="/schools/music">Music</a></li>
									<li><a href="/apply">Apply</a></li>
								</ul>
							</div>
							<div class="c6">
								<ul class="fp1" style="list-style: none; line-height: 2;">
									<li><a href="/about">About</a></li>
									<li><a href="/pages/resources">Resources</a></li>
									<li><a href="/news">News</a></li>
									<li><a href="/">Contact</a></li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		</nav>
	)
}
