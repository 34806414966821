export function linkResolver(doc) {
	if (doc.type === 'home') {
		return '/'
	}
	if (doc.type === 'about') {
		return '/about'
	}
	if (doc.type === 'page') {
		return `/pages/${doc.uid}`
	}
	if (doc.type === 'article') {
		return `/news/${doc.uid}`
	}
	if (doc.type === 'school') {
		return `/${doc.uid}`
	}
	if (doc.type === 'topic') {
		const school = doc.data
			&& doc.data.school
			&& doc.data.school.uid
			|| 'PLEASE_ADD_SCHOOL_TO_TOPIC'
		return `/${school}/${doc.uid}`
	}
	if (doc.type === 'course') {
		const school = doc.data
			&& doc.data.school
			&& doc.data.school.uid
			|| 'PLEASE_ADD_SCHOOL_TO_COURSE'
		const topic = doc.data
			&& doc.data.canonical_topic
			&& doc.data.canonical_topic.uid
			|| 'PLEASE_ADD_TOPIC_TO_COURSE'
		return `/${school}/${topic}/${doc.uid}`
	}
	if (doc.type === 'person') {
		const school = doc.data
			&& doc.data.school
			&& doc.data.school.uid

		if (school) {
			return `/${school}/people/${doc.uid}`
		} else {
			return `/people/${doc.uid}`
		}
	}
	if (doc.link_type === 'Web') {
		return doc.url
	}
	if (doc.link_type === 'Media') {
		return doc.url
	}

	return '/'
}
