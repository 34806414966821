import { html } from 'htm/preact'
import { linkResolver } from '../lib/link-resolver.js'
import { asText, RichText } from '../lib/prismic-render.js'

export function Heading(props) {
	return html`
		<div class="container">
			<div class="x xw gutter">
				<!-- FIXME should this use asText() and force a size? -->
				<div class="c11 md-c5 md-co3 copy">
					<${RichText} richText=${props.primary.heading} />
				</div>
				${(props.primary.link && props.primary.link_text)
					? html`
						<div class="c1 md-c1 md-co2">
							<a class="button mt1" href=${linkResolver(props.primary.link)}>
								${props.primary.link_text}
							</a>
						</div>
					`
					: (props.primary.subheading && html`
						<div class="c12 md-c3">
							<p class="fb">${asText(props.primary.subheading)}</p>
						</div>
					`)}
			</div>
		</div>
	`
}
