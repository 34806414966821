import { h } from 'preact'
import { useState } from 'preact/hooks'
import { prettyLink } from '../lib/links'
import { linkResolver } from '../lib/link-resolver'
import { asText, RichText } from '../lib/prismic-render'

export function LinkList(props) {
	const [index, setIndex] = useState(null)

	if (!props || !props.items || !props.items.length) {
		return null
	}

	return (
		<div class="container">
			{props.primary && props.primary.heading && !!props.primary.heading.length && (
				<div class="x xw gutter">
					<div class="c12 md-c9 md-co3">
						<h2 class="mb2">{asText(props.primary.heading)}</h2>
					</div>
				</div>
			)}
			<div class="x xw gutter">
				<div class="c12 md-c4 md-co3">
					<ul style="line-height: 2; list-style: none;">
						{props.items.map((item, i) => (
							<li
								onMouseEnter={() => setIndex(i)}
								onMouseLeave={() => setIndex(null)}
							>
								<a href={linkResolver(item.link)}>{prettyLink(item.link)}</a>
								<div class="md-dn mb1">
									<RichText richText={item.description} />
								</div>
							</li>
						))}
					</ul>
				</div>
				<div class="dn md-db c12 md-c4 md-co1 psr">
					{props.items.map((item, i) => (
						<div class="psa t0 l0 w100" style={`opacity: ${i === index ? 1 : 0}`}>
							<RichText richText={item.description} />
						</div>
					))}
				</div>
			</div>
		</div>
	)
}
