import { h } from 'preact'
import { useState, useRef, useMemo, useCallback } from 'preact/hooks'
import { FormContextProvider } from './useFormContext'

export function useForm() {
	const [{ values }, setState] = useState({ values: {} })

	const apiRef = useRef()

	const api = useMemo(() => ({
		values
	}), [values])

	apiRef.current = api

	const getFieldValue = useCallback(field => apiRef.current.values[field], [])
	const setFieldValue = useCallback((field, value) => {
		setState(old => ({
			...old,
			values: {
				...old.values,
				[field]: value
			}
		}))
	}, [setState])

	const Form = useCallback(({ children, ...props }) => (
		<FormContextProvider value={{ getFieldValue, setFieldValue }}>
			<form {...props}>
				{children}
			</form>
		</FormContextProvider>
	), [getFieldValue, setFieldValue])

	Object.assign(api, {
		Form,
		// getFieldValue,
		// setFieldValue
	})

	return apiRef.current
}
