import { h } from 'preact'
import { useState, useRef, useCallback } from 'preact/hooks'
import { asText, RichText } from '../lib/prismic-render.js'
import { linkResolver } from '../lib/link-resolver.js'
import { Plus } from './icons.js'

const components = {
	accordion: Accordion,
	grid: Faq
}

export function Expander(props) {
	const Component = components[props.primary.style]

	if (!Component) {
		return null
	}

	return <Component {...props} />
}

// FIXME animate plus to minus on open

function Panel(props) {
	const outer = useRef(null)
	const inner = useRef(null)
	const [open, setOpen] = useState(false)
	const [height, setHeight] = useState(0)

	const handleTransitionEnd = useCallback(event => {
		event.target.removeEventListener('transitionend', handleTransitionEnd)
		setHeight('auto')
	}, [])

	const handleClick = useCallback(() => {
		if (open) {
			setOpen(false)
			setHeight(0)
		} else {
			setOpen(true)
			setHeight(`${inner.current.offsetHeight}px`)

			// FIXME handle browsers that don't support transitionend?
			inner.current.addEventListener('transitionend', handleTransitionEnd)
		}
	}, [open])

	return (
		<div class="bb2">
			<button class="db w100 tal" onClick={handleClick}>
				<div class="py1 pr1 x xjb xac">
					<h3>{asText(props.heading)}</h3>
					<Plus rotate={open} />
				</div>
			</button>
			<div ref={outer} class="oh" style={{ height, transition: 'height 150ms ease-in' }}>
				<div ref={inner} class="py1 copy">
					<RichText richText={props.text} />

					{props.link && props.link_text && (
						<a class="button" href={linkResolver(props.link)}>{props.link_text}</a>
					)}
				</div>
			</div>
		</div>
	)
}

function Accordion(props) {
	return (
		<div class="container">
			<div class="x xw gutter">
				<div class="c12 md-c8 md-co3">
					<div class="bt2">
						{props.items.map(Panel)}
					</div>
				</div>
			</div>
		</div>
	)
}

function FaqItem(props) {
	const outer = useRef(null)
	const inner = useRef(null)
	const [open, setOpen] = useState(false)
	const [height, setHeight] = useState(0)

	const handleTransitionEnd = useCallback(event => {
		event.target.removeEventListener('transitionend', handleTransitionEnd)
		setHeight('auto')
	}, [])

	const handleClick = useCallback(() => {
		if (open) {
			setOpen(false)
			setHeight(0)
		} else {
			setOpen(true)
			setHeight(`${inner.current.offsetHeight}px`)

			// FIXME handle browsers that don't support transitionend?
			inner.current.addEventListener('transitionend', handleTransitionEnd)
		}
	}, [open])

	return (
		<div class="c12 md-c6">
			<div class="mb1 b2 br1">
				<button class={`tal ${open ? 'fwb' : ''} fwb-h`} data-open={open} onClick={handleClick}>
					<p class="px1 py1">{asText(props.heading)}</p>
				</button>
				<div ref={outer} class="oh" style={{ height, transition: 'height 150ms ease-in' }}>
					<div ref={inner} class="px1 pb1 copy">
						<RichText richText={props.text} />
					</div>
				</div>
			</div>
		</div>
	)
}

function Faq(props) {
	return (
		<div class="container">
			<div class="x xw gutter">
				<div class="c12 md-c8 md-co3">
					<div class="x xw gutter">
						{props.items.map(FaqItem)}
					</div>
				</div>
			</div>
		</div>
	)
}
