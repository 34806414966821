export function unique(id = x => x, seen = new Set) {
	return function (item, index, arr) {
		const key = id(item, index, arr)
		return !seen.has(key) && !!seen.add(key)
	}
}

// https://github.com/developit/dlv
export function delve(obj, key, def, p, undef) {
	key = key.split ? key.split('.') : key;
	for (p = 0; p < key.length; p++) {
		obj = obj ? obj[key[p]] : undef;
	}
	return obj === undef ? def : obj;
}
