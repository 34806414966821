import { html } from 'htm/preact'
import { asText } from '../lib/prismic-render.js'

function Segment(props) {
	return html`
		<div class="wsnw py1">
			<p class="x xac h2">
				<a class="button button-flat button-large button-invert mx1" href="/TODO">
					Click here
				</a>
				${asText(props.primary.text)}
			</p>
		</div>
	`
}

export function Marquee(props) {
	return html`
		<div class="marquee psr z4 bgc-black fc-white">
			<div class="oh">
				<div class="marquee-slider x">
					${[1, 2, 3].map(() => html`<${Segment} ...${props} />`)}
				</div>
			</div>
		</div>
	`
}
