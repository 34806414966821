import { html } from 'htm/preact'
import { linkResolver } from '../lib/link-resolver.js'
import { RichText, asText } from '../lib/prismic-render.js'
import { Img } from './img.js'

const components = {
	text: TextGrid, // TODO already removed from CMS, remove here later
	text_2_columns: TextGrid,
	text_3_columns: TextGrid,
	text_smallprint: Smallprint,
	text_image: TextImageGrid,
	text_image_vertical: ResourceList
}

export function Grid(props) {
	const Component = components[props.primary.style]

	if (!Component) {
		return null
	}

	return html`<${Component} ...${props} />`
}

function TextGrid(props) {
	return html`
		<div class="container">
			<div class="x xw gutter">
				<div class="c12 md-c9 md-co3">
					<div class="x xw gutter">
						${props.items.map(item => html`
							<div class="c12 ${props.primary.style === 'text_2_columns' ? 'md-c6' : 'md-c4'} mb4 copy x xdc">
								<h5 style="min-height: 3em">${asText(item.heading, null)}</h5>
								<div class="xx fp2">
									<${RichText} richText=${item.text} />
								</div>
								<div>
									${item.link && item.link_text && html`
										<a class="button" href=${linkResolver(item.link)}>
											${item.link_text}
										</a>
									`}
								</div>
							</div>
						`)}
					</div>
				</div>
			</div>
		</div>
	`
}

function Smallprint(props) {
	return html`
		<div class="container">
			<div class="x xw gutter">
				<div class="c12 md-co3 md-c8">
					<h3 class="body fwb mb0-5">${props.primary.heading && asText(props.primary.heading)}</h3>
					<div class="x xw gutter">
						${props.items.map(item => html`
							<div class="c6 md-c3 fp2">
								<!-- FIXME this is hacky; build a textEmpty() helper -->
								${item.heading && !!item.heading.length && html`
									<h3 class="fp2">${asText(item.heading)}</h3>
								`}
								<${RichText} richText=${item.text} />
							</div>
						`)}
					</div>
				</div>
			</div>
		</div>
	`
}

function TextImageGrid(props) {
	return html`
		<div class="container">
			${props.items.map(item => html`
				<div class="x xw gutter xas xl-xac my2 md-alternate-children">
					<div class="c12 md-c6 xl-c4 copy">
						<h2 class="mb1">${asText(item.heading, null)}</h2>
						<${RichText} richText=${item.text} />
						${item.link && item.link_text && html`
							<a class="button mt1" href=${linkResolver(item.link)}>
								${item.link_text}
							</a>
						`}
					</div>
					<div class="c12 md-c6 xot md-xo1">
						<${Img} class="psr z4" ...${item.image} />
					</div>
				</div>
			`)}
		</div>
	`
}

// FIXME open external links in new tab

function ResourceListItem(props) {
	return html`
		<div class="py1 bb2">
			<div class="x xw gutter">
				<div class="c12 md-c6 x xdc xjb copy">
					<h2>${asText(props.heading, null)}</h2>
					<div>
						<${RichText} richText=${props.text} />
						${props.link && props.link_text && html`
							<a class="button mt1" href=${linkResolver(props.link)}>
								${props.link_text}
							</a>
						`}
					</div>
				</div>
				<div class="c12 md-c6 order-1 md-order1 mb1 md-mb0">
					<${Img} ...${props.image} />
				</div>
			</div>
		</div>
	`
}

function ResourceList(props) {
	return html`
		<div class="container">
			<div class="x xw gutter">
				<div class="c12 md-c8 md-co3">
					<div class="bt2">
						${props.items.map(ResourceListItem)}
					</div>
				</div>
			</div>
		</div>
	`
}
