import { html } from 'htm/preact'

export function Embed(props) {
	if (!props.primary || !props.primary.embed || !props.primary.embed.html) {
		return null
	}

	return html`
		<figure class="container">
			<div class="container-text">
				<div
					class="embed_wrapper"
					style="padding-bottom: ${props.primary.embed.height / props.primary.embed.width * 100}%"
					dangerouslySetInnerHTML=${{ __html: props.primary.embed.html }} />
			</div>
		</figure>
	`
}
