import { useContext, useMemo } from 'preact/hooks';
import { StoreCtx } from './context.js'

export function useContent(conditions) {
	const [store] = useContext(StoreCtx)

	return useMemo(() => store.find(d => {
		return Object.entries(conditions).every(([k, v]) => {
			return d && (d[k] === v);
		});
	}), [store, conditions]);
}
