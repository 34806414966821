import { html } from 'htm/preact'

const widths = [512, 768, 1024, 2048, 4096]

export function Img({ url, alt, dimensions, sizeHint = 80, ...props }) {
	const src = url
	const srcset = widths.map(w => `${url}&w=${w} ${w}w`).join(', ')
	const sizes = [
		'(max-width: 599px) 100vw',
		`${100 * sizeHint / 100}vw`,
		`(min-width: 1480px) ${1480 * sizeHint / 100}px`
	].join(', ')

	return html`
		<img ...${props} src=${src} srcset=${srcset} sizes=${sizes} alt=${alt} />
	`
}

// export function Picture({ Landscape, Square, Portrait, Social, ...props }) {
// 	const sources = [
// 		{
// 			media: '(min-width: 768px)',
// 			img: Landscape
// 		}
// 	]
// 	return html`
// 		<picture>
// 			<source>
// 			<${Img} ...${props} />
// 		</picture>
// 	`
// }
