import regexparam from 'regexparam'
import { routes as rs } from '../queries.js'

function compilePattern({ pattern, ...rest }) {
	return {
		pattern: regexparam(pattern),
		...rest
	}
}

const routes = rs.map(compilePattern)

export function match(path, pattern) {
	let i = 0, out = {}
	let matches = pattern.pattern.exec(path)

	if (!matches) {
		return false
	}

	while (i < pattern.keys.length) {
		out[pattern.keys[i]] = matches[++i] || null
	}

	return out
}

export function fetchData(api, path) {
	const out = []

	for (const r of routes) {
		const p = match(path, r.pattern)

		if (p) {
			for (const q of r.queries) {
				out.push(q(api, p))
			}
		}
	}

	return Promise.all(out)
}
