import { h } from 'preact'

/**
 * Chargebee script is included in template:
 * <script src="https://js.chargebee.com/v2/chargebee.js" data-cb-site="dbsberlin-test" ></script>
 */

export function Payment() {
	return (
		<div class="container">
			<h1>PAY ME</h1>
			<p>
				<a href="javascript:void(0)" data-cb-type="checkout" data-cb-plan-id="enrolment-fee" >subscribe</a>
			</p>
			<p>
				<a href="javascript:void(0)" data-cb-type="portal" >Manage account</a>
			</p>
		</div>
	)
}
