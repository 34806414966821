import { html } from 'htm/preact'

export function Illustration(props) {
	return html`
		<div class="container">
			<div class="x xw gutter">
				<div class="c12 md-co2 md-c10">
					<img src="/illustrations/${props.primary.style || 'music'}.svg" alt="TODO" />
				</div>
			</div>
		</div>
	`
}
