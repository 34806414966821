import { html } from 'htm/preact'
import { useContent } from '../lib/content.js'
import { linkResolver } from '../lib/link-resolver.js'
import { asText } from '../lib/prismic-render.js'
import { Img } from './img.js'

// TODO re-use Embed component here
// to do so, we need to remove layout info from slice components and move it into parent

function NewsItem(props) {
	return html`
		<article class="news_item">
			<a href=${linkResolver(props)}>
				<${Img} ...${props.data.cover_image} />
				<h5>${asText(props.data.title)}</h5>
			</a>
		</article>
	`
}

function MicroStory(props) {
	const block = props.data.body.find(item => item.slice_type === 'embed')

	if (!block) {
		return null
	}

	return html`
		<article class="news_item news_item-microstory">
				<div
					class="embed_wrapper"
					style="padding-bottom: ${block.primary.embed.height / block.primary.embed.width * 100}%"
					dangerouslySetInnerHTML=${{ __html: block.primary.embed.html }} />
				<a href=${linkResolver(props)}>
					<h5>${asText(props.data.title)}</h5>
				</a>
		</article>
	`
}

export function News(props) {
	const { schools, articles } = useContent(props.selector)

	return html`
		<div class="container">
			<header class="x xw gutter" style="margin-top: 5rem;">
				<h1 class="h2 c12 md-co3 md-c4">Read all about it</h1>
				<p class="c12 md-c4">The latest from Catalyst. From student work and events, to news and though-pieces.</p>
			</header>
			<nav class="mt3 mb1 x xw xab gutter">
				<div class="c12 md-co3 md-c8 xl-c4">
					${schools.map(school => html`
						<a class="button fp2 mr0-5" href="/news/school/${school.uid}">
							${asText(school.data.title)}
						</a>
					`)}
					<a class="button fp2 mr0-5" href="/news/school/catalyst">Catalyst</a>
				</div>
				<div class="c12 md-co3 md-c8 xl-co0 xl-c4 mt2 xl-mt0 ">
					<input type="search" class="searchbar" placeholder="Search News" />
				</div>
			</nav>
			<div class="container-text news">
				${articles.map(article => {
					if (article.data.microstory) {
						return html`<${MicroStory} ...${article} />`
					}

					return html`<${NewsItem} ...${article} />`
				})}
			</div>
		</div>
	`
}
