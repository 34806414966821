import { html } from 'htm/preact'
import { asText, RichText } from '../lib/prismic-render.js'
import { Img } from './img.js'
import { linkResolver } from '../lib/link-resolver.js'

// FIXME proper margin-bottom from scale

function Person(props) {
	if (!props || !props.data) {
		return null
	}

	return html`
		<div class="psr ar100 mb1 filter-grayscale filter-none-h nudge-h">
			${props.data.image && props.data.image.url && html`
				<${Img} class="psa t0 l0 w100 h100" style="object-fit: cover" sizeHint=${30} ...${props.data.image} />
			`}
		</div>
		${props.data.name && html`
			<p><strong>${asText(props.data.name)}</strong></p>
		`}
		<!-- <${RichText} richText=${props.bio} /> -->
	`
}

export function Tutors({ tutors }) {
	return html`
		<div class="container">
			<div class="x xw gutter">
				<div class="c12 md-c10 md-co2">
					<div class="x xw gutter">
						${tutors.map(tutor => html`
							<div class="c12 md-c4 mb3 pb1">
								<a href=${linkResolver(tutor)}>
									<${Person} ...${tutor} />
								</a>
							</div>
						`)}
					</div>
				</div>
			</div>
		</div>
	`
}
