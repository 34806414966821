import { createContext } from 'preact'
import { useContext } from 'preact/hooks'

const formContext = createContext()
const { Provider } = formContext

export { Provider as FormContextProvider }

export function useFormContext() {
	const formApi = useContext(formContext)

	if (!formApi) {
		throw new Error('Trying to use form API outside of a Form component.')
	}

	return formApi
}
