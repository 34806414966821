import { h, Fragment } from 'preact'
import classnames from 'clsx'
import { useInView } from 'react-intersection-observer'
import { Switch, Route } from 'wouter-preact'
import { Logotype, Minilogo, Slash } from './logo'

export function Header() {
	return (
		<header class="header container">
			<div class="header_left">
				<Switch>
					<Route path="/">
					</Route>
					<Route path="/:x*">
						<a href="/">
						<Logotype style={{ width: '500px', height: 'auto' }} />
					</a>
					<Switch>
						<Route path="/film/:x*">
							<a class="ml0-25" href="/film"><Slash />film</a>
						</Route>
						<Route path="/music/:x*">
							<a class="ml0-25" href="/music"><Slash />music</a>
						</Route>
						<Route path="/acting/:x*">
							<a class="ml0-25" href="/acting"><Slash />acting</a>
						</Route>
					</Switch>
					</Route>
				</Switch>
			</div>
			<Menu />
		</header>
	)
}

export function MiniNav() {
	const [ref, top] = useInView()

	return (
		<>
			<div class="mininav_sentinel" ref={ref} />
			<nav class={classnames('mininav', !top && 'mininav-visible')}>
				<div class="mininav_inner container">
					<div class="mininav_left">
						<a href="/"><Minilogo /></a>
						<Slash />
						<Switch>
							<Route path="/film/:x*">
								<a href="/film">film</a>
							</Route>
							<Route path="/music/:x*">
								<a href="/music">music</a>
							</Route>
							<Route path="/acting/:x*">
								<a href="/acting">acting</a>
							</Route>
							<Route path="/:x*">
								<a href="/film">film</a>
								<Slash />
								<a href="/music">music</a>
								<Slash />
								<a href="/acting">acting</a>
							</Route>
						</Switch>
					</div>

					<Menu />
				</div>
			</nav>
		</>
	)
}

function Menu() {
	return (
		<ul class="menu fp2">
			<Dropdown />
			<li><a href="/about">About</a></li>
			<li><a href="/pages/resources">Resources</a></li>
			<li><a href="/news">News</a></li>
			<li><a class="button" href="/apply">Apply</a></li>
		</ul>
	)
}

function Dropdown() {
	return (
		<div class="dropdown">
			<div class="dropdown_title">Schools</div>
			<ul class="dropdown_items" style="background-color: var(--background-color)">
					<li><a href="/film">Film</a></li>
					<li><a href="/music">Music</a></li>
					<li><a href="/acting">Acting</a></li>
			</ul>
		</div>
	)
}
