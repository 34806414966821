import { html } from 'htm/preact'
import { Logo } from './logo.js'

export function Footer() {
	return html`
		<footer class="psr z5 container mb1">
			<div class="bgc-black fc-white pt4">
				<div class="x xw gutter">
					<div class="c12 md-co2 md-c3 mb2">
						<${Logo} />
					</div>
				</div>
				<div class="x xw gutter">
					<div class="c12 md-c3 md-co2 mb4">
						<nav>
							<h3 class="mb1">Schools</h3>
							<ul class="lsn lh2">
								<li><a class="pb1" href="/film">Film</a></li>
								<li><a class="pb1" href="/music">Music</a></li>
								<li><a class="pb1" href="/acting">Acting</a></li>
							</ul>
						</nav>
					</div>
					<div class="c12 md-c3 mb4">
						<nav>
							<h3 class="mb1">Catalyst</h3>
							<ul class="lsn lh2">
								<li><a class="pb1" href="/about">About</a></li>
								<li><a class="pb1" href="">News</a></li>
								<li><a class="pb1" href="">Resources</a></li>
								<li><a class="pb1" href="">Careers</a></li>
							</ul>
						</nav>
					</div>
					<div class="c12 md-c3 mb4">
						<nav>
							<h3 class="mb1">Stay in touch</h3>
							<p>Join our mailing list</p>
						</nav>
					</div>
				</div>
				<div class="x xw gutter">
					<div class="c12 md-c3 md-co2 mb4">
						<nav>
							<h3 class="mb1">Services</h3>
							<ul class="lsn lh2">
								<li><a class="pb1" href="">Record at Catalyst</a></li>
								<li><a class="pb1" href="">Koalition</a></li>
							</ul>
						</nav>
					</div>
					<div class="c12 md-c5 mb4">
						<nav>
							<h3 class="mb1">Partners</h3>
							<ul class="lsn lh2 x xac">
								${[1,2,3,4,5].map(() => html`
									<li>
										<div
											style="
												width: 48px;
												height: 48px;
												border-radius: 48px;
												background-color: #fff;
												opacity: 0.5;
												margin-right: 1rem
											"
										></div>
									</li>
								`)}
							</ul>
						</nav>
					</div>
				</div>
			</div>
		</footer>
	`
}
