import { h, Fragment } from 'preact'
import { useState, useEffect, useContext } from 'preact/hooks'
import { Router, Switch, Route, useLocation } from 'wouter-preact'
import locationHook from 'wouter-preact/use-location'
import staticLocationHook from 'wouter-preact/static-location'

import { prismicEndpoint } from './config.js'
import { getApi } from './lib/prismic-api.js'
import { StoreCtx, ApiCtx } from './lib/context.js'
import { fetchData } from './lib/fetch.js'
import { useBackgroundColor } from './lib/section.js'

import { Helmet } from 'react-helmet-async'

import { Header, MiniNav } from './components/nav.js'
import { HeaderMobile } from './components/nav-mobile.js'
import { Page } from './components/page.js'
import { Payment } from './components/payment.js'
import { Form } from './components/forms/form.js'
import { News } from './components/news.js'
import { Footer } from './components/footer.js'
import { CookieBar } from './components/cookie-bar.js'

import { Grid, Outline } from './components/debug.js'

function dynamicLocationHook(options) {
	const [path, navigate] = locationHook(options)
	const apiRef = useContext(ApiCtx)
	const [, setStore] = useContext(StoreCtx)

	function _navigate(to, replace) {
		(apiRef.v || (apiRef.v = getApi(prismicEndpoint)))
			.then(api => fetchData(api, to))
			.then(data => {
				setStore(prev => prev.concat(data))
				navigate(to, replace)

				// Scroll to top if not navigating within courses of a topic
				// FIXME find a prettier way to do this
				const re = /\/([^/]+)\/([^/]+)\/([^/]+)/

				const oldMatch = path.match(re)
				const newMatch = to.match(re)

				const sameSection = oldMatch && newMatch && (oldMatch[1] === newMatch[1]) && (oldMatch[2] === newMatch[2])

				if (!sameSection) {
					window.scrollTo(0, 0)
				}
			})
	}

	return [path, _navigate]
}

function AppUI() {
	const [, navigate] = useLocation()

	// wire up <a> clicks to router
	useEffect(() => {

		function click(event) {
			const anchor = event.target.closest('a')
			const href = anchor && anchor.getAttribute('href')

			if (
				event.ctrlKey ||
				event.metaKey ||
				event.altKey ||
				event.shiftKey ||
				event.button ||
				event.defaultPrevented
			) {
				return
			}
			if (
				!href ||
				anchor.target ||
				anchor.host !== location.host
			) {
				return
			}

			event.preventDefault()
			navigate(href)
		}

		addEventListener('click', click)
		return () => {
			removeEventListener('click', click)
		}
	}, [])

	const bgc = useBackgroundColor()

	return (
		<>
			<Helmet>
				<body style={`--background-color: var(--${bgc})`} />
			</Helmet>

			<div class="dn xl-db">
				<Header />
				<MiniNav />
			</div>

			<div class="xl-dn">
				<HeaderMobile />
			</div>

			<main>
				<Switch>
					<Route path="/">
						<Page selector={{ type: 'home' }} />
					</Route>
					<Route path="/about">
						<Page selector={{ type: 'about' }} />
					</Route>
					<Route path="/payment">
						<Payment />
					</Route>
					<Route path="/apply">
						<Form />
					</Route>
					<Route path="/pages/:page">
						{params => <Page selector={{ type: 'page', uid: params.page }} />}
					</Route>
					<Route path="/news/school/:school">
						{params => <News selector={{ type: 'news', school: params.school }} />}
					</Route>
					<Route path="/news">
						{params => <News selector={{ type: 'news' }} />}
					</Route>
					<Route path="/news/:article">
						{params => <Page selector={{ type: 'article', uid: params.article }} />}
					</Route>
					<Route path="/:school">
						{params => <Page selector={{ type: 'school', uid: params.school }} />}
					</Route>
					<Route path="/:school?/people/:person">
						{params => <Page selector={{ type: 'person', uid: params.person }} />}
					</Route>
					<Route path="/:school/:topic/:course?">
						{params => <Page selector={{ type: 'topic', uid: params.topic }} />}
					</Route>
				</Switch>
			</main>

			<Footer />
			<CookieBar />

			{process.env.NODE_ENV !== 'production' && (
				<>
					<Grid />
					<Outline />
				</>
			)}
		</>
	)
}

export function App(props) {
	const store = useState(props.data)
	const hook = process.browser
		? dynamicLocationHook
		: staticLocationHook(props.url)

	return (
		<StoreCtx.Provider value={store}>
			<Router hook={hook}>
				<AppUI />
			</Router>
		</StoreCtx.Provider>
	)
}
