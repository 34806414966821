import { h } from 'preact'
import { useState, useEffect } from 'preact/hooks'
import { useForm, encodeForm } from '../../lib/forms'
import {
	RichText,
	Text,
	Email,
	Name,
	Phone,
	Address,
	TextArea
} from './fields'

/**
 * TODO
 * - sections
 */

export function Form() {
	const { values, Form } = useForm()
	const [formData, setFormData] = useState(null)

	useEffect(async () => {
		const res = await fetch('/form/3765589')
		const data = await res.json()
		setFormData(data)
	}, [])

	// TODO remove
	useEffect(() => {
		console.log({formData})
		console.log({values})
	})

	async function handleSubmit(event) {
		event.preventDefault()
		console.log(event)

		const response = await fetch('/form/3765589/submission', {
			method: 'POST',
			mode: 'cors',
			cache: 'no-cache',
			credentials: 'include',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/x-www-form-urlencoded'
			},
			body: encodeForm({
				...values,
				remote_addr: 'foo',
				user_agent: 'bar'
			})
		})

		try {
			const json = await response.json()
			// TODO remove
			console.log(json)
		} catch(e) {}
	}

	return (
		<div class="container">
			<div class="x xw gutter">
				<div class="c12 md-c3">
					<ol>
						{formData && formData.fields.map(field => {
							if (field.type !== 'section') {
								return null
							}

							return <li>{field.section_heading}</li>
						})}
					</ol>
				</div>
				<div class="c12 md-c8">
					<Form onSubmit={handleSubmit}>
						{formData && formData.fields.map(field => {
							const fieldName = `field_${field.id}`

							switch (field.type) {
								case 'richtext':
									return <RichText {...field} />
								case 'text':
									return <Text {...field} field={fieldName} />
								case 'email':
									return <Email {...field} field={fieldName} />
								case 'phone':
									return <Phone {...field} field={fieldName} />
								case 'name':
									return <Name {...field} field={fieldName} />
								case 'address':
									return <Address {...field} field={fieldName} />
								case 'textarea':
									return <TextArea {...field} field={fieldName} />
							}
						})}

						<button type="submit" class="button">SUBMIT</button>
					</Form>
				</div>
			</div>
		</div>
	)
}
