import { h, Fragment } from 'preact'
import { useCallback } from 'preact/hooks'
import { useField } from '../../lib/forms/useField'

export function RichText({ section_text }) {
	return <div dangerouslySetInnerHTML={{ __html: section_text }} />
}

export function Input({ type = 'text', field, label, placeholder }) {
	const { value, setValue } = useField(field)

	const handleInput = useCallback(event => {
		setValue(event.target.value)
	}, [setValue])

	return (
		<p>
			{label && <label for={field}>{label}</label>}
			<input type={type} placeholder={placeholder} value={value} id={field} name={field} onInput={handleInput} />
		</p>
	)
}

export function Text({ type, ...props }) {
	return (
		<Input type="text" {...props} />
	)
}

export function Email({ type, ...props }) {
	return (
		<Input type="email" {...props} />
	)
}

export function Phone({ type, ...props }) {
	return (
		<Input type="tel" {...props} />
	)
}

export function Name({ type, field, label, ...props }) {
	return (
		<>
			<Input type="text" {...props} field={`${field}[first]`} label="First Name" />
			<Input type="text" {...props} field={`${field}[last]`} label="Last Name" />
		</>
	)
}

export function Address({ type, field, label, ...props }) {
	console.log({props})
	return (
		<>
			<Input type="text" {...props} field={`${field}[address]`} label="Address Line 1" />
			<Input type="text" {...props} field={`${field}[address2]`} label="Address Line 2" />
			<Input type="text" {...props} field={`${field}[city]`} label="City" />
			<Input type="text" {...props} field={`${field}[state]`} label="State/Province" />
			<Input type="text" {...props} field={`${field}[zip]`} label="Zip/Postal" />
		</>
	)
}

export function TextArea({ field, label, placeholder }) {
	const { value, setValue } = useField(field)

	const handleInput = useCallback(event => {
		setValue(event.target.value)
	}, [setValue])

	return (
		<p>
			{label && <label for={field}>{label}</label>}
			<textarea placeholder={placeholder} id={field} name={field} onInput={handleInput} />
		</p>
	)
}
