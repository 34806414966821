import { h, Fragment } from 'preact';

export function asText(raw) {
	return raw.map(p => p.text).join(' ');
}

export function parse(raw) {
	const out = [];

	for (const item of raw) {
		if (/(o-)?list-item/.test(item.type)) {
			const type = item.type.replace(/-item$/, '');
			const prev = out.length && out[out.length - 1];

			const transformed = {
				type: item.type,
				children: [item.text]
			};

			if (prev && prev.type === type) {
				prev.children.push(transformed);
			} else {
				out.push({
					type,
					children: [transformed]
				});
			}
		} else if (/heading[123456]|paragraph/.test(item.type)) {
			out.push({
				type: item.type,
				children: [item.text]
			});
		}
	}

	return out;
}

function serializeSpan(content) {
	if (content) {
		return content.split('\n').reduce((acc, p) => {
			if (acc.length === 0) {
				return [p];
			} else {
				return acc.concat([h('br'), p]);
			}
		}, []);
	} else {
		return null;
	}
}

function render(richText) {
	return richText.map(p => {
		switch (p.type) {
			case 'heading1':
				return h('h1', null, render(p.children));
			case 'heading2':
				return h('h2', null, render(p.children));
			case 'heading3':
				return h('h3', null, render(p.children));
			case 'heading4':
				return h('h4', null, render(p.children));
			case 'heading5':
				return h('h5', null, render(p.children));
			case 'heading6':
				return h('h6', null, render(p.children));
			case 'paragraph':
				return h('p', null, render(p.children));
			case 'list':
				return h('ul', null, render(p.children));
			case 'o-list':
				return h('ol', null, render(p.children));
			case 'list-item':
				return h('li', null, render(p.children));
			case 'o-list-item':
				return h('li', null, render(p.children));
			default:
				// TODO explicitly handle text node case and return null for default
				return serializeSpan(p); // text node
		}
	});
}

export function RichText({ richText, Component = Fragment, ...props }) {
	const parsed = parse(richText);
	const children = render(parsed);
	return h(Component, props, children);
}
